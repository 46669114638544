import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { useGetStoreDealsQuery } from '../../services/search';
import Skeleton from '@material-ui/lab/Skeleton';
import { addProductImg } from "../../Reducers/authReducer";
import Loader from '../../Components/Loader';
import Button from '@mui/joy/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import GradeIcon from '@mui/icons-material/Grade';
import SearchLoader from '../../Components/SearchLoader';
import { useGetUserDataQuery } from '../../services/login';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HelmetComponent from '../../Components/HelmetComponent';
import formatPrice from '../../Components/formatPrice';
import ProductCountries from '../../Components/Header/ProductCountries';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
var moment = require('moment');

function StoreDeals() {
  const { userId, userToken, userEmail, languageCode, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { query } = useParams();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, reset: reset2 } = useForm();
  const search = new URLSearchParams(location.search).get('search') || null
  const searchId = new URLSearchParams(location.search).get('search_id') || null
  const thisSearch = search ? `&search=${search}` : ''
  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 48
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  const [getProducts, setProductsData] = useState([]);
  const [getPage, setPage] = useState(1);
  const [getSearchId, setSearchId] = useState(0);

  const { data: newPosts = [], isLoading, isError, isFetching } = useGetStoreDealsQuery({
    page: getPage,
    size: limit,
    query: query,
    search: search,
    search_id: getSearchId,
    countryCode: countryCode
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const products = newPosts?.results

  React.useEffect(() => {
    if (products?.length > 0) {
      setProductsData((prevPosts) => [...prevPosts, ...products]);
    }
  }, [newPosts]);


  useEffect(() => {
    if (products) {
      setProductsData(() => [...products]);
      setPage(1)
    }
  }, [query]);


  useEffect(() => {
    if (Number(getPage) === 1 && products) {
      setProductsData((prevPosts) => [...products]);
    }
  }, [getPage, products]);


  useEffect(() => {
    if (Number(getPage) === 1 && isFetching) {
      setProductsData((prevPosts) => []);
    }
  }, [getPage, query]);


  const lastId = getProducts?.slice(-1)[0]?.id || 0;

  const loadMore = () => {
    setPage((prevPage) => prevPage + 1);
    setSearchId(lastId)
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s]/gi, ' ')?.replace(/ /g, '-')
  }

  function addProductImage(data) {
    dispatch(addProductImg({ productImg: data }));
  }
  const handleError = (e) => {
    console.error('Error loading image:', e.target.src);
    e.target.src = 'https://cdn.ebtida.com/1705315901965-Image-not-found.jpg';
  };

  const beforeLoadHandler = () => (
    <img
      className="img-item"
      src='https://cdn.ebtida.com/1705315901965-Image-not-found.jpg' />
  );

  function ratingFucn(rating) {
    if (rating === 0) {
      return false
    } else if (rating === '0') {
      return false
    } else if (!rating) {
      return false
    } else {
      return true
    }
  }
  function loadFetch() {
    if (isLoading) {
      return true
    } else if (isFetching) {
      return true
    } else {
      return false
    }
  }
  function getDeliverydays(delivery) {
    const deliveryDaya = Number(delivery) + Number(userData?.contant?.delivery_days || 0)
    function getTimeline() {
      if (Number(deliveryDaya) === 1) {
        return <>Delivery by <strong>Tomorrow</strong> {moment().add(Number(deliveryDaya), 'days').format('Do')}</>
      } else {
        return <>Delivery by {moment().add(Number(deliveryDaya), 'days').format('Do MMM')}</>
      }
    }
    if (Number(deliveryDaya) > 0) {
      return getTimeline()
    } else {
      return `Delivery by ${moment().add(10, 'days').format('Do MMM')}`
    }
  }

  const soteIds = [3, 1]
  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }


  function searchOrder(data) {
    const result = data?.orderId;
    history.push(`${window.location.pathname}?search=${result}`)
  }

  function clearSearch() {
    reset2()
    history.push(`${window.location.pathname}`)
  }

  return (
    <>
      <HelmetComponent title={'Store search'} />
      <div className="sp-style">
        <div className="search-section" style={{ position: 'relative' }}>
          {isLoading &&
            <div className='sp-search-loader'>
              <div>
                <div className='sp-query' style={{ margin: '2px 0px', fontSize: '1rem', textAlign: 'center' }}><i>{t('Searching for') + '  ' + 'Warehouse deals'}</i></div>
                <SearchLoader />
              </div>
            </div>
          }

          <div className="container">
            <div className="row">
              {!isLoading &&
                <div style={{ width: '100%', padding: '10px 0px 10px 0px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>

                    <div className='search-cardTitle col-md-7' style={{ textAlign: 'start' }}>
                      <div className='search-result-secton'>
                        <div className='sp-query'>
                          <span>
                            Warehouse Deals Upto&nbsp;
                            <span
                              className='alert alert-danger'
                              style={{ background: '#fff0', border: 'none', padding: 0, margin: 0, fontWeight: 600 }}>
                              50% Off
                            </span>
                          </span>
                        </div>
                        <span style={{ display: 'flex', margin: '0px 5px' }}>
                          {loadFetch() && <div><SearchLoader /></div>}
                        </span>
                      </div>
                    </div>
                    <div className='col-md-5'>
                      <div style={{ position: 'relative' }}>
                        {/* <form
                        onSubmit={handleSubmit2(searchOrder)}
                        style={{ maxWidth: '100%' }}
                      >
                        <input
                          style={{ height: '40px', borderRadius: '50px' }}
                          defaultValue={search}
                          type="text"
                          className="form-control"
                          placeholder="Search in deals..."
                          id="orderId"
                          aria-invalid={errors2.orderId ? "true" : "false"}
                          {...register2("orderId", { required: true })}
                        />
                        {!search &&
                          <IconButton
                            size='sm'
                            style={{ top: '4px', right: '5px', position: 'absolute', borderRadius: '100px' }}
                            type="submit"
                          >
                            <SearchIcon style={{ fontSize: '1.4rem' }} />
                          </IconButton>
                        }
                        {search &&
                          <IconButton
                            size='sm'
                            style={{ top: '4px', right: '5px', position: 'absolute', borderRadius: '100px' }}
                            onClick={(() => clearSearch())}
                          >
                            <CloseIcon style={{ fontSize: '1.4rem' }} />
                          </IconButton>}
                      </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {!isLoading &&
                <>
                  {!isFetching &&
                    <>
                      {getProducts?.length === 0 &&
                        <div className='no-result-found-secton'>
                          <ErrorOutlineOutlinedIcon />
                          <div className='cardTitle'>Looks like we couldn't find what you're searching for.</div>
                        </div>
                      }
                    </>
                  }
                </>
              }

              {getProducts?.map((item, i) =>
                <div className="col-md-12 cardItem" style={{ padding: '5px' }} key={i}>
                  <Link
                    className="cardItem-item"
                    onClick={(() => addProductImage(item?.image))}
                    to={`/product/${item?.store_id}/${functionIdLink(item?.id, item?.pid, item?.store_id)}/${productTitle(item?.title)}`}
                  >

                    <div className="img-card imgCard">
                      <LazyLoadImage
                        delayTime={100}
                        key={i}
                        alt={item?.title}
                        effect="blur"
                        className="img-item"
                        src={item?.image}
                        onError={handleError}
                        beforeLoad={beforeLoadHandler}
                      />
                      <div className='delivery-days'
                        style={{ position: 'absolute', right: '5px', bottom: '-4px', color: '#000', fontSize: '12px', lineHeight: 1, padding: '1px 5px 1px 5px', borderRadius: '2px', width: 'auto' }}>
                        <small>
                          <i>
                            <BoltOutlinedIcon style={{ fontSize: '1rem', color: '#fe7f4a' }} />
                            <b>Fast delivery</b>
                          </i>
                        </small>
                      </div>
                    </div>
                    <div className="card-content">
                      <div className='title-card' style={{ maxHeight: '45px', minHeight: '45px' }}>
                        <h4>{item?.title?.replace(/[^\w\s.,'/:]/gi, ' ')}</h4>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          {ratingFucn(item?.rating) &&
                            <div className='rating' style={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ display: 'flex', alignItems: 'center', color: '#a8a8a8', margin: '0px 3px', fontSize: '14px' }}>{item?.rating}</span>
                              <GradeIcon style={{ color: '#fe7f4a', fontSize: '1rem', marginTop: '-1.5px' }} />
                            </div>
                          }
                        </div>
                      </div>
                      <div className="content-item">
                        <div className='item-price-card'>
                          {Number(item?.prices?.value) === 0 ?
                            <span className='badge badge-secondary'>Check Price</span>
                            :
                            <>
                              <span className='item-deal-price'>
                                {formatPrice(item?.prices?.value, item?.prices?.currency, 0)}&nbsp;
                              </span>
                              {item?.prices?.deal_value &&
                                <span className='item-price'>
                                  <small style={{ textDecoration: 'line-through', }}>
                                    {formatPrice(item?.prices?.price, item?.prices?.currency, 0)}
                                  </small>
                                </span>
                              }
                            </>
                          }
                        </div>
                      </div>
                      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span className='item-price mobile-hide'>
                          <small style={{ textDecoration: 'line-through', }}>
                          </small> 50% Off
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: '#444' }}>
                          {t('From')}&nbsp;
                          <img
                            style={{ width: '20px', objectFit: 'contain' }}
                            loading="lazy"
                            src={`https://flagcdn.com/w20/${item?.country?.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${item?.country?.toLowerCase()}.png 2x`}
                            alt=""
                          />&nbsp;
                          <div>{ProductCountries(item?.country)}</div>
                        </div>
                      </div>
                    </div>

                  </Link>
                </div>
              )}


              {loadFetch() &&
                <>
                  {Array(20).fill().map((_, index) => (
                    <div className="col-md-3 cardItem" style={{ padding: '5px' }} key={index}>
                      <div className="cardItem-item">
                        <div className="img-card imgCard">
                          <Skeleton className='skeleton img-item' variant="rect" style={{ width: '90%', height: '90%' }} />
                        </div>
                        <div className="card-content" style={{ width: '100%' }}>
                          <div className='title-card'>
                            <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '100%' }} />
                            <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '90%', marginTop: '5px' }} />
                          </div>
                          <div className="content-item">
                            <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '50%' }} />
                          </div>
                          <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                            <Skeleton className='skeleton' variant="rect" style={{ height: '12px', width: '30%' }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              }

            </div>
            <br />
          </div>
          {getProducts?.length > 0 &&
            <div className='search-paginattion'>
              <div className='container'>
                <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  <>
                    <Button
                      style={{ border: '0.5px solid #39414a', color: '#39414a', fontWeight: 400, height: '40px', margin: '0px 10px' }}
                      disabled={isFetching}
                      variant='outlined'
                      onClick={loadMore}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{loadFetch() ? 'Loading' : 'Load More'}</span>
                        {loadFetch() && <span style={{ margin: '0px 10px' }}><Loader /></span>}
                      </div>
                    </Button>
                  </>
                </div>
              </div>
            </div>
          }
        </div>
        <br />
        <br />
      </div >
    </>
  );
}

export default StoreDeals;
