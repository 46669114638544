const threeDecCurrencies = ['BHD', 'KWD', 'OMR']

function formatPrice(price, currency, shipping) {
  const numericPrice = Number(price) + Number(shipping);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency ? currency : 'AED',
    minimumFractionDigits: threeDecCurrencies?.includes(currency) ? 2 : 0,
    maximumFractionDigits: threeDecCurrencies?.includes(currency) ? 2 : 0,
  })?.format(numericPrice);
};

export default formatPrice;