import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Button from '@mui/joy/Button';
import { useTranslation } from 'react-i18next'
import { filter } from 'lodash';

function Variation({ productData, productVarData, isLoading, isFetching, id, variant, isVarLoading, isVarFetching }) {
  const { userId, userToken, cartToken, languageCode, countryCode } = useSelector((state) => state.auth);
  const { t } = useTranslation()

  function variantsFetching() {
    if (isFetching) {
      return true
    } else if (isVarFetching) {
      return true
    } else {
      return false
    }
  }

  function variantsLoading() {
    if (isLoading) {
      return true
    } else if (isVarLoading) {
      return true
    } else {
      return false
    }
  }


  const vVariations = productData?.variants?.variations || productVarData?.variants?.variations
  const vAtrributes = productData?.variants?.variation_attributes || productVarData?.variants?.variation_attributes
  const cVariant = productData?.current_variant || productVarData?.current_variant

  const variants = vVariations || {};
  const variationAttributes = vAtrributes;
  const currentVariant = cVariant || {};

  const variantEntries = Object.entries(variants);
  const uniqueAttributes = {};

  variationAttributes?.forEach((attr) => {
    uniqueAttributes[attr] = [...new Set(variantEntries?.map(([_, v]) => v[attr]))];
  });

  const availableOptions = {};

  variationAttributes?.forEach((attr) => {
    availableOptions[attr] = variantEntries
      .filter(([_, v]) => v[attr] === currentVariant[attr])
      .map(([asin, v]) => v);
  });

  // const variantsArray = Object.entries(variants).map(([id, details]) => ({
  //   id,
  //   ...details
  // }));


  const [getVariantA, setVariantA] = useState('');


  return (
    <>
      {variantsLoading() ?
        <div style={{ width: '60%' }}>
          <Skeleton count={2} inline={true} variant="rect" style={{ height: '35px', width: '45%', margin: '0px 3px' }} />
        </div>
        :
        <>

          {variationAttributes?.[0] &&
            <>
              {/* {variationAttributes?.map((attr) => ( */}
              <div key={variationAttributes?.[0]} style={{ filter: variantsFetching() && 'opacity(0.5)', padding: '5px 0px 5px 0px' }}>
                <div style={{ padding: '0px 0px 3px 0px', fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
                  <span className='product-brand' style={{ fontWeight: 500, padding: 0 }}>{variationAttributes?.[0]?.replace(':', '')}:</span>
                  <span style={{ margin: '0px 5px', color: '#6d6d6d' }}>{currentVariant[variationAttributes?.[0]]}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                  {uniqueAttributes[variationAttributes?.[0]]?.slice(0, getVariantA === variationAttributes?.[0] ? 100 : 5)?.map((value) => {
                    const variant = variantEntries?.find(
                      ([asin, v]) => v[variationAttributes?.[0]] === value && (currentVariant[variationAttributes?.[0]] !== value)
                    );
                    const isSelected = currentVariant[variationAttributes?.[0]] === value;
                    return ( value ?
                      <Button
                        key={value}
                        style={{
                          width: 'auto',
                          minHeight: '30px',
                          borderRadius: '4px',
                          margin: '3px 3px',
                          textTransform: 'capitalize',
                          color: isSelected ? '#4e93d9' : '#302e2e',
                          fontWeight: isSelected ? 400 : 400,
                          fontSize: '14px',
                          border: isSelected ? '1px solid #97c3f0' : '1px solid #0000001f',
                          padding: '5px 15px'
                        }}
                        variant="outlined"
                        component={Link}
                        to={`/product/${productData?.store_id}/${id}?variant=${variant ? variant[0] : "#"}&country=${productData?.country}`}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{value}</span>
                        </div>
                      </Button> : ''
                    );
                  })}
                  {uniqueAttributes[variationAttributes?.[0]]?.length > 5 &&
                    <div
                      variant="outlined"
                      onClick={(() => setVariantA(getVariantA === variationAttributes?.[0] ? '' : variationAttributes?.[0]))}
                      style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
                      <div>
                        {getVariantA === variationAttributes?.[0] ? t('see_less') : `${t('see_all')} ${uniqueAttributes[variationAttributes?.[0]]?.length}`}</div>
                      <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
                    </div>
                  }
                </div>
              </div>
              {/* ))} */}
            </>
          }



          {variationAttributes?.[1] &&
            <>
              {/* {variationAttributes?.map((attr) => ( */}
              <div key={variationAttributes?.[1]} style={{ filter: variantsFetching() && 'opacity(0.5)', padding: '5px 0px 5px 0px' }}>
                <div style={{ padding: '0px 0px 3px 0px', fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
                  <span className='product-brand' style={{ fontWeight: 500, padding: 0 }}>{variationAttributes?.[1]?.replace(':', '')}:</span>
                  <span style={{ margin: '0px 5px', color: '#6d6d6d' }}>{currentVariant[variationAttributes?.[1]]}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                  {uniqueAttributes[variationAttributes?.[1]]?.slice(0, getVariantA === variationAttributes?.[1] ? 100 : 5)?.map((value) => {
                    const variant = variantEntries?.find(
                      ([asin, v]) => v[variationAttributes?.[1]] === value
                    );
                    const isSelected = currentVariant[variationAttributes?.[1]] === value;
                    return ( value ?
                      <Button
                        disabled={variant ? false : true}
                        key={value}
                        style={{
                          width: 'auto',
                          minHeight: '30px',
                          borderRadius: '4px',
                          margin: '3px 3px',
                          textTransform: 'capitalize',
                          color: isSelected ? '#4e93d9' : '#302e2e',
                          fontWeight: isSelected ? 400 : 400,
                          fontSize: '14px',
                          border: isSelected ? '1px solid #97c3f0' : '1px solid #0000001f',
                          padding: '5px 15px',
                          filter: variant ? 'opacity(1)' : 'opacity(0.5)'
                        }}
                        variant="outlined"
                        component={Link}
                        to={`/product/${productData?.store_id}/${id}?variant=${variant ? variant[0] : "#"}&country=${productData?.country}`}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{value}</span>
                        </div>
                      </Button> : ''
                    );
                  })}
                  {uniqueAttributes[variationAttributes?.[1]]?.length > 5 &&
                    <div
                      variant="outlined"
                      onClick={(() => setVariantA(getVariantA === variationAttributes?.[1] ? '' : variationAttributes?.[1]))}
                      style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
                      <div>
                        {getVariantA === variationAttributes?.[1] ? t('see_less') : `${t('see_all')} ${uniqueAttributes[variationAttributes?.[1]]?.length}`}</div>
                      <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
                    </div>
                  }
                </div>
              </div>
              {/* ))} */}
            </>
          }


          {variationAttributes?.[2] &&
            <>
              {/* {variationAttributes?.map((attr) => ( */}
              <div key={variationAttributes?.[2]} style={{ filter: variantsFetching() && 'opacity(0.5)', padding: '5px 0px 5px 0px' }}>
                <div style={{ padding: '0px 0px 3px 0px', fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
                  <span className='product-brand' style={{ fontWeight: 500, padding: 0 }}>{variationAttributes?.[2]?.replace(':', '')}:</span>
                  <span style={{ margin: '0px 5px', color: '#6d6d6d' }}>{currentVariant[variationAttributes?.[2]]}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                  {uniqueAttributes[variationAttributes?.[2]]?.slice(0, getVariantA === variationAttributes?.[2] ? 100 : 5)?.map((value) => {
                    const variant = variantEntries?.find(
                      ([asin, v]) => v[variationAttributes?.[2]] === value
                    );
                    const isSelected = currentVariant[variationAttributes?.[2]] === value;
                    return ( value ? 
                      <Button
                        disabled={variant ? false : true}
                        key={value}
                        style={{
                          width: 'auto',
                          minHeight: '30px',
                          borderRadius: '4px',
                          margin: '3px 3px',
                          textTransform: 'capitalize',
                          color: isSelected ? '#4e93d9' : '#302e2e',
                          fontWeight: isSelected ? 400 : 400,
                          fontSize: '14px',
                          border: isSelected ? '1px solid #97c3f0' : '1px solid #0000001f',
                          padding: '5px 15px',
                          filter: variant ? 'opacity(1)' : 'opacity(0.5)'
                        }}
                        variant="outlined"
                        component={Link}
                        to={`/product/${productData?.store_id}/${id}?variant=${variant ? variant[0] : "#"}&country=${productData?.country}`}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{value}</span>
                        </div>
                      </Button> : ''
                    );
                  })}
                  {uniqueAttributes[variationAttributes?.[2]]?.length > 5 &&
                    <div
                      variant="outlined"
                      onClick={(() => setVariantA(getVariantA === variationAttributes?.[2] ? '' : variationAttributes?.[2]))}
                      style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
                      <div>
                        {getVariantA === variationAttributes?.[2] ? t('see_less') : `${t('see_all')} ${uniqueAttributes[variationAttributes?.[2]]?.length}`}</div>
                      <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
                    </div>
                  }
                </div>
              </div>
              {/* ))} */}
            </>
          }


          {variationAttributes?.[3] &&
            <>
              {/* {variationAttributes?.map((attr) => ( */}
              <div key={variationAttributes?.[3]} style={{ filter: variantsFetching() && 'opacity(0.5)', padding: '5px 0px 5px 0px' }}>
                <div style={{ padding: '0px 0px 3px 0px', fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
                  <span className='product-brand' style={{ fontWeight: 500, padding: 0 }}>{variationAttributes?.[3]?.replace(':', '')}:</span>
                  <span style={{ margin: '0px 5px', color: '#6d6d6d' }}>{currentVariant[variationAttributes?.[3]]}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                  {uniqueAttributes[variationAttributes?.[3]]?.slice(0, getVariantA === variationAttributes?.[3] ? 100 : 5)?.map((value) => {
                    const variant = variantEntries?.find(
                      ([asin, v]) => v[variationAttributes?.[3]] === value
                    );
                    const isSelected = currentVariant[variationAttributes?.[3]] === value;
                    return ( value ? 
                      <Button
                        disabled={variant ? false : true}
                        key={value}
                        style={{
                          width: 'auto',
                          minHeight: '30px',
                          borderRadius: '4px',
                          margin: '3px 3px',
                          textTransform: 'capitalize',
                          color: isSelected ? '#4e93d9' : '#302e2e',
                          fontWeight: isSelected ? 400 : 400,
                          fontSize: '14px',
                          border: isSelected ? '1px solid #97c3f0' : '1px solid #0000001f',
                          padding: '5px 15px',
                          filter: variant ? 'opacity(1)' : 'opacity(0.5)'
                        }}
                        variant="outlined"
                        component={Link}
                        to={`/product/${productData?.store_id}/${id}?variant=${variant ? variant[0] : "#"}&country=${productData?.country}`}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{value}</span>
                        </div>
                      </Button> : ''
                    );
                  })}
                  {uniqueAttributes[variationAttributes?.[3]]?.length > 5 &&
                    <div
                      variant="outlined"
                      onClick={(() => setVariantA(getVariantA === variationAttributes?.[3] ? '' : variationAttributes?.[3]))}
                      style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
                      <div>
                        {getVariantA === variationAttributes?.[3] ? t('see_less') : `${t('see_all')} ${uniqueAttributes[variationAttributes?.[3]]?.length}`}</div>
                      <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
                    </div>
                  }
                </div>
              </div>
              {/* ))} */}
            </>
          }


          {variationAttributes?.[4] &&
            <>
              {/* {variationAttributes?.map((attr) => ( */}
              <div key={variationAttributes?.[4]} style={{ filter: variantsFetching() && 'opacity(0.5)', padding: '5px 0px 5px 0px' }}>
                <div style={{ padding: '0px 0px 3px 0px', fontSize: '14px', display: 'flex', alignItems: 'center', margin: '0px 3px' }}>
                  <span className='product-brand' style={{ fontWeight: 500, padding: 0 }}>{variationAttributes?.[4]?.replace(':', '')}:</span>
                  <span style={{ margin: '0px 5px', color: '#6d6d6d' }}>{currentVariant[variationAttributes?.[4]]}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', flexWrap: 'wrap' }}>
                  {uniqueAttributes[variationAttributes?.[4]]?.slice(0, getVariantA === variationAttributes?.[4] ? 100 : 5)?.map((value) => {
                    const variant = variantEntries?.find(
                      ([asin, v]) => v[variationAttributes?.[4]] === value
                    );
                    const isSelected = currentVariant[variationAttributes?.[4]] === value;
                    return ( value ? 
                      <Button
                        disabled={variant ? false : true}
                        key={value}
                        style={{
                          width: 'auto',
                          minHeight: '30px',
                          borderRadius: '4px',
                          margin: '3px 3px',
                          textTransform: 'capitalize',
                          color: isSelected ? '#4e93d9' : '#302e2e',
                          fontWeight: isSelected ? 400 : 400,
                          fontSize: '14px',
                          border: isSelected ? '1px solid #97c3f0' : '1px solid #0000001f',
                          padding: '5px 15px',
                          filter: variant ? 'opacity(1)' : 'opacity(0.5)'
                        }}
                        variant="outlined"
                        component={Link}
                        to={`/product/${productData?.store_id}/${id}?variant=${variant ? variant[0] : "#"}&country=${productData?.country}`}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span>{value}</span>
                        </div>
                      </Button> : ''
                    );
                  })}
                  {uniqueAttributes[variationAttributes?.[4]]?.length > 5 &&
                    <div
                      variant="outlined"
                      onClick={(() => setVariantA(getVariantA === variationAttributes?.[4] ? '' : variationAttributes?.[4]))}
                      style={{ fontSize: '12px', cursor: 'pointer', color: '#97c3f0', margin: '0 3px', display: 'flex', alignItems: 'center', height: '30px', width: 'auto' }}>
                      <div>
                        {getVariantA === variationAttributes?.[4] ? t('see_less') : `${t('see_all')} ${uniqueAttributes[variationAttributes?.[4]]?.length}`}</div>
                      <ChevronRightIcon style={{ margin: '0 5px', fontSize: '15px' }} />
                    </div>
                  }
                </div>
              </div>
              {/* ))} */}
            </>
          }
        </>
      }

    </>
  );
}

export default Variation;
