import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { Tag } from "antd";
import { useGetUserDataQuery } from '../../../services/login';
import formatPrice from '../../../Components/formatPrice';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocalMallIcon from '@mui/icons-material/LocalMall';


var moment = require('moment');
function OrdersList({ orders }) {
  const { userId, userToken, userEmail, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  if (!userEmail && !userToken) {
    history.push('/')
  }
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })

  function getDeliverydays(data, created) {
    if (Number(data) > 0) {
      return <>{moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = orders?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});


  const [isExpand, setExpand] = useState('');

  function openTracking(data) {
    setExpand(data)
  }

  function getShippingStep(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else {
      return false
    }
  }

  function getShippingStep1(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else {
      return false
    }
  }


  const soteIds = [3, 1]
  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }
  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s.,]/gi, ' ')?.replace(/ /g, '-')
  }

  function getProcessing(status, arived, departed) {
    const processingState = ['dispatched', 'arrived_in_US', 'departed_from_US', 'arrived_in_UK', 'departed_from_UK', 'arrived_in_IN', 'departed_from_IN', 'arrived_in_AE', 'departed_from_AE', 'out_for_delivery', 'delivered']
    const toFindArray = status?.split(',');
    if (toFindArray?.includes(arived)) {
      return true
    } else if (toFindArray?.includes(departed)) {
      return true
    } else {
      return processingState?.some(item => toFindArray?.includes(item));
    }
  }

  function getDispatched(status, arived, departed) {
    const processingState = ['arrived_in_US', 'departed_from_US', 'arrived_in_UK', 'departed_from_UK', 'arrived_in_IN', 'departed_from_IN', 'arrived_in_AE', 'departed_from_AE', 'out_for_delivery', 'delivered']
    const toFindArray = status?.split(',');

    if (toFindArray?.includes(arived)) {
      return true
    } else if (toFindArray?.includes(departed)) {
      return true
    } else {
      return processingState.some(item => toFindArray?.includes(item));
    }
  }

  function getShipping(status) {
    const processingState = ['out_for_delivery', 'delivered']
    const toFindArray = status?.split(',');
    return processingState.some(item => toFindArray?.includes(item));
  }

  function getShipping2(status, curent) {
    const processingState = ['out_for_delivery', 'delivered']
    const toFindArray = status?.split(',');
    if (status?.split(',')?.includes(curent)) {
      return true
    } else {
      return processingState.some(item => toFindArray?.includes(item));
    }
  }

  function getShipping3(status, curent) {
    const processingState = ['out_for_delivery', 'delivered', 'arrived_in_uae', 'departed_from_uae']
    const toFindArray = status?.split(',');
    if (status?.split(',')?.includes(curent)) {
      return true
    } else {
      return processingState.some(item => toFindArray?.includes(item));
    }
  }

  function isOrderConfirm(state, status, item_state) {
    if (status) {
      if (state == 'request_cancel') {
        return false
      } else if (state == 'cancelled') {
        return false
      } else if (item_state == 'request_cancel') {
        return false
      } else if (item_state == 'cancelled') {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  function isItemCancelled(item_state) {
    if (item_state == 'request_cancel') {
      return <span className='badge badge-warning'>Requested for cancellation</span>
    } else if (item_state == 'cancelled') {
      return <span className='badge badge-danger'>Item has been cancelled</span>
    } else {
      return ''
    }
  }


  return (
    <>
      {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
        <div
          key={group}
          style={{
            border: '1px solid #eee',
            borderRadius: '10px',
            padding: '15px',
            background: '#efefef3b',
            marginBottom: '15px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
            {isOrderConfirm(orders?.states, orders?.status) &&
              <div style={{ fontSize: '14px', fontWeight: 500 }}>
                {t('delivery_by')}&nbsp;{getDeliverydays(group, orders?.createdAt)}
              </div>
            }
            <div style={{ fontSize: '14px', fontWeight: 500 }}>{t('items')}: {groupItems?.length}</div>
          </div>
          <div className='op-item-section' style={{ display: 'block' }}>
            {groupItems?.map((items, index) =>
              <div
                key={index}
                style={{ background: '#fff', padding: '15px', marginBottom: '10px', borderRadius: '10px', }}>
                {/* <div style={{ fontSize: '1.2rem', fontWeight: 500, marginBottom: '10px', textTransform: 'uppercase', textAlign: 'center' }}>{items.shipping_state}</div> */}
                {isOrderConfirm(orders?.states, orders?.status, items.state) &&
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', position: 'relative', height: '55px' }}>
                    <Stepper
                      className="step-card-list"
                      size="lg"
                      sx={{
                        width: '100%',
                        position: 'absolute',
                        '--StepIndicator-size': '2rem',
                        '--Step-connectorInset': '0px',
                        [`& .${stepIndicatorClasses.root}`]: {
                          borderWidth: 2,
                        },
                        [`& .${stepClasses.root}::after`]: {
                          height: 2,
                        },
                        [`& .${stepClasses.completed}`]: {
                          [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'primary.300',
                            // color: 'primary.300',
                          },
                          '&::after': {
                            bgcolor: 'primary.300',
                          },
                        },
                        [`& .${stepClasses.active}`]: {
                          [`& .${stepIndicatorClasses.root}`]: {
                            border: '3px solid',
                            borderColor: '#fff',
                            boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.warning[500]}`,
                          },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                          color: 'neutral.outlinedDisabledColor',
                        },
                      }}
                    >
                      <Step
                        className="step-item-list"
                        active={items.shipping_state == 'processing'}
                        completed={getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'solid' : items.shipping_state == 'processing' ? 'solid' : 'outlined'}
                            color={getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'success' : items.shipping_state == 'processing' ? 'warning' : 'neutral'}>
                            {getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ?
                              <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                              <FiberManualRecordIcon className='checkthiss' style={{ fontSize: '20px' }} />
                            }
                          </StepIndicator>}
                      >
                        <Typography
                          style={{ gap: '1px' }}
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.7rem',
                            letterSpacing: '0.5px',
                            gap: '2px'
                          }}
                        >
                          Processing
                        </Typography>
                      </Step>

                      <Step
                        className="step-item-list"
                        active={items.shipping_state == 'dispatched'}
                        completed={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'solid' : items.shipping_state == 'dispatched' ? 'solid' : 'outlined'}
                            color={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'success' : items.shipping_state == 'dispatched' ? 'warning' : 'neutral'}>
                            {getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ?
                              <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                              <LocalMallIcon style={{ fontSize: '20px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.7rem',
                            letterSpacing: '0.5px',
                          }}
                        >
                          Procuring
                        </Typography>
                      </Step>
                      <Step
                        className="step-item-list"
                        active={getShippingStep1(items.shipping_state)}
                        completed={getShipping(items.shipping_status)}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={getShippingStep(items.shipping_status) ? 'solid' : getShipping(items.shipping_status) ? 'solid' : 'outlined'}
                            color={getShipping(items.shipping_status) ? 'success' : getShippingStep(items.shipping_status) ? 'warning' : 'neutral'}>
                            {getShipping(items.shipping_status) ?
                              <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                              <LocalShippingRoundedIcon style={{ fontSize: '20px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.7rem',
                            letterSpacing: '0.5px',
                          }}
                        >
                          Shipping
                        </Typography>
                      </Step>
                      <Step
                        className="step-item-list"
                        completed={items.shipping_state == 'delivered'}
                        orientation="vertical"
                        indicator={
                          <StepIndicator
                            variant={items.shipping_state == 'delivered' ? 'solid' : 'outlined'}
                            color={items.shipping_state == 'delivered' ? 'success' : 'neutral'}>
                            {items.shipping_state == 'delivered' ?
                              <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                              <CheckCircleRoundedIcon style={{ fontSize: '20px' }} />
                            }
                          </StepIndicator>
                        }
                      >
                        <Typography
                          sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'lg',
                            fontSize: '0.7rem',
                            letterSpacing: '0.5px',
                          }}
                        >
                          Delivered
                        </Typography>
                      </Step>
                    </Stepper>
                  </div>
                }

                <div className='op-item-card' style={{ border: 'none' }}>
                  <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                    <img className='op-item-img' style={{ borderRadius: '100px', border: '1px solid #eee' }} src={items.image} />
                  </Link>
                  <div className='op-item-title'>
                    <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                      <p style={{ margin: '0px 5px', fontSize: '14px', marginBottom: '5px' }}>
                        {items.title?.replace(/[^\w\s.,/:']/gi, ' ')}
                      </p>
                    </Link>
                    <div className='op-item-content'>
                      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        <div className='badge badge-light' style={{ background: '#fff0' }}>{formatPrice(items.price, items.currency ? items.currency : userData?.contant?.currency, 0)}</div>
                        <div className='badge badge-light' style={{ background: '#fff0', fontWeight: 400 }}>Condition: {items.conditions}</div>
                        <div className='badge badge-light' style={{ fontWeight: 400, background: '#fff0' }}>Qty.: {items.quantity}</div>
                      </div>
                    </div>
                    {items.current_variant &&
                      <div style={{ padding: '5px 0px 5px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                        {Object.entries(items.current_variant || []).map(([key, value], index) => (
                          <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                        ))}
                      </div>
                    }
                  </div>
                </div>
                <div className='oip-f-jc-c'>
                  {isOrderConfirm(orders?.states, orders?.status, items.state) &&
                    <div>
                      <IconButton
                        className='oip-f-button'
                        style={{ minHeight: '30px', padding: '0px 10px 0px 10px', borderRadius: '6px', marginBottom: '5px' }}
                        size='sm'
                        variant='outlined'
                        onClick={(() => openTracking(isExpand === `open${items.id}` ? '' : `open${items.id}`))}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ fontSize: '13px' }}>Item Tracking</span>
                          {isExpand === `open${items.id}` && <ExpandLessIcon style={{ fontSize: '22px' }} />}
                          {isExpand !== `open${items.id}` && <ExpandMoreIcon style={{ fontSize: '22px' }} />}
                        </div>
                      </IconButton>

                      {isExpand === `open${items.id}` &&
                        <Stepper
                          style={{ marginTop: '20px' }}
                          orientation="vertical"
                          sx={{
                            '--Stepper-verticalGap': '1.5rem',
                            '--StepIndicator-size': '1.5rem',
                            '--Step-gap': '1rem',
                            '--Step-connectorInset': '0rem',
                            '--Step-connectorRadius': '1rem',
                            '--Step-connectorThickness': '1.5px',
                            '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',

                            [`& .${stepClasses.completed}`]: {
                              '&::after': { bgcolor: 'success.solidBg' },
                            },
                            [`& .${stepClasses.active}`]: {
                              [`& .${stepIndicatorClasses.root}`]: {
                                border: '2px solid',
                                borderColor: '#fff',
                                boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.warning[500]}`,
                              },
                            },
                            [`& .${stepClasses.disabled} *`]: {
                              color: 'neutral.softDisabledColor',
                            },
                            [`& .${typographyClasses['title-sm']}`]: {
                              textTransform: 'uppercase',
                              letterSpacing: '1px',
                              fontSize: '10px',
                            },
                          }}
                        >
                          <Step
                            active={items.shipping_state == 'processing'}
                            completed={getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                            indicator={
                              <StepIndicator
                                variant={getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'solid' : items.shipping_state == 'processing' ? 'solid' : 'outlined'}
                                color={getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'success' : items.shipping_state == 'processing' ? 'warning' : 'neutral'}>
                                {getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                  <span style={{ fontSize: '12px' }}>1</span>
                                }
                              </StepIndicator>
                            }
                          >
                            <div>
                              <Button
                                disabled={items.shipping_state == 'processing' ? false : !getProcessing(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  <small>Estimated 1 day</small><br />
                                  Processing
                                </div>
                              </Button>
                            </div>
                          </Step>

                          <Step
                            active={items.shipping_state == 'dispatched'}
                            completed={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                            indicator={
                              <StepIndicator
                                variant={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'solid' : items.shipping_state == 'dispatched' ? 'solid' : 'outlined'}
                                color={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'success' : items.shipping_state == 'dispatched' ? 'warning' : 'neutral'}>
                                {getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                                  <span style={{ fontSize: '12px' }}>2</span>
                                }
                              </StepIndicator>
                            }
                          >
                            <div>
                              <Button
                                disabled={items.shipping_state == 'dispatched' ? false : !getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  <small>{items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 2-3 days'}</small><br />
                                  Procuring
                                </div>
                              </Button>
                            </div>
                          </Step>

                          <Step
                            active={items.shipping_state === 'dispatched'}
                            completed={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                            indicator={
                              <StepIndicator
                                variant={items.shipping_state === 'dispatched' ? 'solid' : 'outlined'}
                                color={getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`) ? 'success' : items.shipping_state === 'dispatched' ? 'warning' : 'neutral'}>
                                <FiberManualRecordIcon style={{ fontSize: '13px' }} />
                              </StepIndicator>}>
                            <div>
                              <Button
                                disabled={items.shipping_state == 'dispatched' ? false : !getDispatched(items.shipping_status, `arrived_in_uae`, `departed_from_uae`)}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  Dsipatched from Supplier
                                </div>
                              </Button>
                            </div>
                          </Step>


                          <Step
                            active={getShippingStep1(items.shipping_state)}
                            completed={getShipping(items.shipping_status)}
                            indicator={
                              <StepIndicator
                                variant={getShippingStep(items.shipping_status) ? 'solid' : getShipping(items.shipping_status) ? 'solid' : 'outlined'}
                                color={getShipping(items.shipping_status) ? 'success' : getShippingStep(items.shipping_status) ? 'warning' : 'neutral'}>
                                {getShipping(items.shipping_status) ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                  <span style={{ fontSize: '12px' }}>3</span>
                                }
                              </StepIndicator>}>
                            <div>
                              <Button
                                disabled={getShippingStep1(items.shipping_state) ? false : !getShipping(items.shipping_status)}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  <small>{items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 3-5 days'}</small><br />
                                  Shipping and Fulfillment
                                </div>
                              </Button>
                            </div>

                          </Step>
                          {items.country !== 'AE' &&
                            <>
                              <Step
                                active={items.shipping_state === `arrived_in_${items.country}`}
                                completed={getShipping3(items.shipping_status, `departed_from_${items.country}`)}
                                indicator={
                                  <StepIndicator
                                    variant={items.shipping_state === `arrived_in_${items.country}` ? 'solid' : 'outlined'}
                                    color={getShipping3(items.shipping_status, `departed_from_${items.country}`) ? 'success' : items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`) ? 'warning' : 'neutral'}>
                                    <FiberManualRecordIcon style={{ fontSize: '13px' }} />
                                  </StepIndicator>}>
                                <div>
                                  <Button
                                    disabled={items.shipping_state === `arrived_in_${items.country}` ? false : !getShipping3(items.shipping_status, `departed_from_${items.country}`)}
                                    variant='light'
                                    className='shipping-step-card'
                                  >
                                    <div level="title-sm">
                                      Arrived in {items.country} warehouse
                                    </div>
                                  </Button>
                                </div>
                              </Step>

                              <Step
                                active={items.shipping_state === `departed_from_${items.country}`}
                                completed={getShipping3(items.shipping_status, `arrived_in_uae`)}
                                indicator={
                                  <StepIndicator
                                    variant={items.shipping_state === `departed_from_${items.country}` ? 'solid' : 'outlined'}
                                    color={getShipping3(items.shipping_status, `arrived_in_uae`) ? 'success' : items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ? 'warning' : 'neutral'}>
                                    <FiberManualRecordIcon style={{ fontSize: '13px' }} />
                                  </StepIndicator>}>
                                <div>
                                  <Button
                                    disabled={items.shipping_state === `departed_from_${items.country}` ? false : !getShipping3(items.shipping_status, `arrived_in_uae`)}
                                    variant='light'
                                    className='shipping-step-card'
                                  >
                                    <div level="title-sm">
                                      Departed from {items.country} warehouse
                                    </div>
                                  </Button>
                                </div>
                              </Step>
                            </>
                          }
                          <Step
                            active={items.shipping_state === `arrived_in_uae`}
                            completed={getShipping2(items.shipping_status, `departed_from_uae`)}
                            indicator={
                              <StepIndicator
                                variant={items.shipping_state === `arrived_in_uae` ? 'solid' : 'outlined'}
                                color={getShipping2(items.shipping_status, `departed_from_uae`) ? 'success' : items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ? 'warning' : 'neutral'}>
                                <FiberManualRecordIcon style={{ fontSize: '13px' }} />
                              </StepIndicator>}>
                            <div>
                              <Button
                                disabled={items.shipping_state === `arrived_in_uae` ? false : !getShipping2(items.shipping_status, `departed_from_uae`)}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  Arrived in UAE warehouse
                                </div>
                              </Button>
                            </div>
                          </Step>

                          <Step
                            active={items.shipping_state === `departed_from_uae`}
                            completed={getShipping(items.shipping_status)}
                            indicator={
                              <StepIndicator
                                variant={items.shipping_state === `departed_from_uae` ? 'solid' : 'outlined'}
                                color={getShipping(items.shipping_status) ? 'success' : items.shipping_status?.split(',')?.includes(`departed_from_uae`) ? 'warning' : 'neutral'}>
                                <FiberManualRecordIcon style={{ fontSize: '13px' }} />
                              </StepIndicator>}>
                            <div>
                              <Button
                                disabled={items.shipping_state === `departed_from_uae` ? false : !getShipping(items.shipping_status)}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  Departed from UAE warehouse
                                </div>
                              </Button>
                            </div>
                          </Step>

                          <Step
                            active={items.shipping_state === `out_for_delivery`}
                            completed={items.shipping_state === `delivered`}
                            indicator={
                              <StepIndicator
                                variant={items.shipping_state === `out_for_delivery` ? 'solid' : items.shipping_state === `delivered` ? 'solid' : 'outlined'}
                                color={items.shipping_state === `delivered` ? 'success' : items.shipping_state === `out_for_delivery` ? 'warning' : 'neutral'}>
                                {items.shipping_state === `delivered` ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                  <span style={{ fontSize: '12px' }}>4</span>
                                }
                              </StepIndicator>}>
                            <div>
                              <Button
                                disabled={items.shipping_state === `out_for_delivery` ? false : items.shipping_state !== `delivered`}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  Out for delivery
                                </div>
                              </Button>
                            </div>
                          </Step>
                          <Step
                            completed={items.shipping_state == 'delivered'}
                            indicator={
                              <StepIndicator
                                variant={items.shipping_state == 'delivered' ? 'solid' : 'outlined'}
                                color={items.shipping_state == 'delivered' ? 'success' : 'neutral'}>
                                {items.shipping_state == 'delivered' ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '13px' }} /> :
                                  <span style={{ fontSize: '12px' }}>5</span>
                                }
                              </StepIndicator>}>
                            <div>
                              <Button
                                disabled={items.shipping_state != 'delivered'}
                                variant='light'
                                className='shipping-step-card'
                              >
                                <div level="title-sm">
                                  <small>{items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 2-3 days'}</small><br />
                                  Delivered
                                </div>
                              </Button>
                            </div>
                          </Step>
                        </Stepper>
                      }
                    </div>
                  }

                  {isItemCancelled(items.state)}

                  {isOrderConfirm(orders?.states, orders?.status, items.state) &&
                    <div className='oip-f-status'>
                      {items.state === 'cancelled' ?
                        <div className='alert alert-danger' style={{ whiteSpace: 'normal', marginBottom: '10px', textTransform: 'uppercase', padding: 0, border: 'none', background: '#fff0', fontSize: '0.8rem' }}>
                          Item has been cancelled
                        </div>
                        :
                        <>
                          {items.delivered_on &&
                            <>
                              {items.shipping_state === 'delivered' &&
                                <div className='alert alert-info' style={{ whiteSpace: 'normal', marginBottom: '10px', textTransform: 'uppercase', padding: 0, border: 'none', background: '#fff0', fontSize: '0.8rem' }}>
                                  <span style={{ fontWeight: 400 }}>
                                    {t('Delivered On')}&nbsp;{moment(items.delivered_on).format('lll')}</span>
                                </div>
                              }
                            </>
                          }
                          {items.shipping_state !== 'delivered' &&
                            <div className='alert alert-info' style={{ whiteSpace: 'normal', marginBottom: '10px', textTransform: 'uppercase', padding: 0, border: 'none', background: '#fff0', fontSize: '0.8rem' }}>
                              <span>{items.shipping_state?.replace(/_/g, ' ')}</span>
                            </div>
                          }
                        </>
                      }
                    </div>
                  }

                </div>
              </div>
            )}
          </div>
        </div >
      ))
      }
    </>
  );
}

export default OrdersList;