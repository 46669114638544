import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import 'react-phone-number-input/style.css'
import Button from '@mui/joy/Button';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';

function ZiinaApplePay({ checkoutWithZiinaApple, checkoutWithZiinaApple2, isZiinaApplePaySelected2, isZiinaApplePaySelected, isShipmentAddress }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, isPurchase } = useSelector((state) => state.auth);
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();

  return (
    <>
      <Button
        disabled={isShipmentAddress}
        className='cp-pm-button'
        variant='plain'
        size='sm'
        onClick={() => checkoutWithZiinaApple({
          selected: true,
          payment_by: 'ziina_payment'
        })}>
        <div className='cp-pm-button-card'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RadioGroup>
              <FormControlLabel
                disabled={isShipmentAddress}
                style={{ margin: 0 }}
                value={true}
                control={<Radio checked={isZiinaApplePaySelected} />} />
            </RadioGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '0.9rem' }}>Pay with&nbsp;</span>
                {/* <CreditCardOutlinedIcon style={{ fontSize: '1.2rem', marginRight: '5px' }} /> */}
                <span><img src='/google-pay.png' style={{ width: '45px' }} />&nbsp;</span>
                <span>Or&nbsp;</span>
                <span><img src='/apple-pay.png' style={{ width: '55px' }} />&nbsp;</span>
              </span>
            </div>
          </div>
        </div>
      </Button>

      <Button
        disabled={isShipmentAddress}
        className='cp-pm-button'
        variant='plain'
        size='sm'
        onClick={() => checkoutWithZiinaApple2({
          selected: true,
          payment_by: 'ziina_payment'
        })}>
        <div className='cp-pm-button-card'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <RadioGroup>
              <FormControlLabel
                disabled={isShipmentAddress}
                style={{ margin: 0 }}
                value={true}
                control={<Radio checked={isZiinaApplePaySelected2} />} />
            </RadioGroup>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ fontSize: '0.9rem' }}>Pay with credit or debit card&nbsp;</span>
                <CreditCardOutlinedIcon style={{ fontSize: '1.2rem' }} />
              </span>
            </div>
          </div>
        </div>
      </Button>
    </>
  );
}

export default ZiinaApplePay;
