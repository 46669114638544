import React, { useState, useEffect, Fragment } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import Host from "../Host";
import { useForm } from "react-hook-form";
import { Modal } from 'react-bootstrap'
import Button from '@mui/joy/Button';
import IconButton from '@mui/joy/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import Countries from '../Header/Countries';
import { useCreateShippingAddressMutation } from '../../services/login';

import ShippingCountry from '../../Helpers/ShippingCountry';

function ShippingAddressModal({ isModal, closeShippingAddressModal }) {
  const { userId, userToken, userEmail, userName, languageCode, countryCode, cartToken, guestShippment, guestPayment, isPurchase } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
  const selectedCityCode = watch("city");
  const { t } = useTranslation()

  const shippingInfo = ShippingCountry?.filter((l) => l.country_code == countryCode)
  const cityName = shippingInfo?.find((l) => l.city_code == selectedCityCode)

  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)

  const [createshipping, createresult] = useCreateShippingAddressMutation()

  function createshippingaddress(data) {
    if (isValidPhoneNumber(getPhone)) {
      createshipping({
        userId: userId,
        userToken: userToken,
        name: data.name,
        email: userEmail,
        address: data.address,
        street: data.street,
        city_code: data.city,
        city: cityName?.city_name,
        country: cityName?.country_name,
        country_code: cityName?.country_code,
        zip_code: data.zip_code,
        phone: getPhone,
        countryCode: countryCode
      })
    } else {
      setPhoneError(true)
    }
  }
  useEffect(() => {
    if (createresult?.isSuccess) {
      closeShippingAddressModal()
      reset()
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [createresult?.isSuccess])

  return (

    <Modal className="shipping-address-modal" show={isModal} onHide={closeShippingAddressModal} centered animation={false}>
      <Modal.Header>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <div style={{ fontWeight: 500, fontSize: '1.1rem' }}>
            Add new shipping address
          </div>
          <IconButton
            variant='outlined'
            size='sm'
            color='danger'
            style={{ minHeight: '27px', maxHeight: '27px' }}
            onClick={closeShippingAddressModal}
          >
            <CloseIcon className="Header-close-modal-icon" style={{ fontSize: '1.2rem' }} />
          </IconButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        {createresult?.error?.data?.error &&
          <div style={{ paddingTop: 20 }}>
            <div style={{ textAlign: 'center', color: '#ea8087' }}>{createresult?.error?.data?.error}</div>
          </div>
        }
        <form onSubmit={handleSubmit(createshippingaddress)}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('name')} </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  aria-invalid={errors.name ? "true" : "false"}
                  {...register('name', { required: true })} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('address_flat')} </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  aria-invalid={errors.address ? "true" : "false"}
                  {...register('address', { required: true })} />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('street_area')} </label>
                <input
                  type="text"
                  className="form-control"
                  id="street"
                  aria-invalid={errors.street ? "true" : "false"}
                  {...register('street', { required: true })} />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('city')} </label>
                <select
                  id="city"
                  aria-invalid={errors.city ? "true" : "false"}
                  {...register('city', { required: false })}
                  className="form-control">
                  {shippingInfo?.map((item, i) =>
                    <option value={item?.city_code}>{item?.city_name}</option>
                  )}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('country')} </label>
                <select className="form-control">
                  <option disabled selected>{shippingInfo?.[0]?.country_name}</option>
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('phone')} </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry={countryCode}
                  value={getPhone}
                  onChange={setPhone}
                />
                {getPhoneError &&
                  <>
                    {getPhone && isValidPhoneNumber(getPhone) ? '' :
                      <div><small style={{ color: 'tomato' }}>{'Invalid phone number'}</small></div>
                    }
                  </>
                }
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label style={{ fontSize: '14px' }}>{t('zip_code')} </label>
                <input
                  type="number"
                  className="form-control"
                  id="zip_code"
                  aria-invalid={errors.zip_code ? "true" : "false"}
                  {...register('zip_code', { required: false })} />
              </div>
            </div>
            <div className="col-md-12 Header-signin-button">
              <div className="form-group">
                <Button
                  disabled={createresult.isLoading}
                  style={{ background: Host?.bgColor, color: Host?.buttonColor, width: '100%', height: '45px' }}
                  type="submit">
                  {createresult.isLoading ? t('loading') : t('submit')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default ShippingAddressModal;
