import React, { useState, useEffect, useMemo, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './Pages/Home';
import Shop from './Pages/Shop';
import Settings from './Pages/Account/Settings';
import Membership from './Pages/Account/Membership';
// import PaymentMethod from './Pages/Account/PaymentMethod';
import Support from './Pages/Account/Support';
import Purchase from './Pages/Purchase';
import PurchaseSuccess from './Pages/PurchaseSuccess';

import TapPurchaseTest from "./Pages/TapPurchaseTest";
import TapPurchaseGuestTest from "./Pages/TapPurchaseGuestTest";

import TapPurchaseGuest from "./Pages/TapPurchaseGuest";
import TapPurchase from "./Pages/TapPurchase";

import CheckoutPage from "./Pages/CheckoutPage";
import CheckoutConfirm from "./Pages/CheckoutConfirm";

import Careers from "./Pages/Careers";
import Login from './Pages/Login';
import Pricing from './Pages/Pricing';
import StoreSearch from "./Pages/StoreSearch";
import StoreQuerySearch from "./Pages/StoreQuerySearch";
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import AboutUs from "./Pages/AboutUs";
import FAQS from "./Pages/FAQS";
import PurchaseGuest from "./Pages/PurchaseGuest";
import PayByPurchaseGuest from "./Pages/PayByPurchaseGuest";
import PayByPurchase from "./Pages/PayByPurchase";
import ShippingPolicy from "./Pages/ShippingPolicy";
import PaymentPolicy from "./Pages/PaymentPolicy";
import ReturnsRefund from "./Pages/ReturnsRefund";
import ContactUs from "./Pages/ContactUs";
import TrackOrder from "./Pages/TrackOrder";
import Product from "./Pages/Product";
import Order from "./Pages/Account/Order";
import AccountWallet from "./Pages/Account/AccountWallet";
import ShippingAddresses from "./Pages/Account/ShippingAddresses";
import { useGetUserDataQuery } from './services/login';
import StoreDeals from "./Pages/StoreDeals";
import AccountOverview from "./Pages/Account";
import { useSelector } from "react-redux";
import Pages from './Layout/Pages';
import NotFound from './NotFound';

function App() {
  const { userId, userToken, countryCode } = useSelector((state) => state.auth);
  const { data: userData } = useGetUserDataQuery({ userId: userId, userToken: userToken, countryCode: countryCode }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const currency = userData?.contant?.currency

  const currencies = ['AED', 'SAR', 'BHD', 'EGP', 'EUR', 'GBP', 'KWD', 'OMR', 'QAR']

  const thisDomain = window.location.hostname === 'www.thwifty.ae' && currency === 'AED' ? true : false

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={[
            '/shop',
            '/:language/shop'
          ]}>
            <Pages Components={Shop} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/track-order',
            '/:language/track-order'
          ]}>
            <Pages Components={TrackOrder} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/search/:query',
            '/:language/search/:query'
          ]}>
            <Pages Components={Pricing} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/account/order/:orderNumber',
            '/:language/account/order/:orderNumber'
          ]}>
            <Pages Components={Order} account={true} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/account/account-wallet',
            '/:language/account/account-wallet'
          ]}>
            <Pages Components={AccountWallet} account={true} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/account/shipping-addresses',
            '/:language/account/shipping-addresses'
          ]}>
            <Pages Components={ShippingAddresses} account={true} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/store/query/:query',
            '/:language/store/query/:query'
          ]}>
            <Pages Components={StoreQuerySearch} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/store/deals',
            '/:language/store/deals'
          ]}>
            <Pages Components={StoreDeals} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/store/:storeId',
            '/:language/store/:storeId'
          ]}>
            <Pages Components={StoreSearch} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/product/:storeId/:id',
            '/:language/product/:storeId/:id',
            '/:language/:country/product/:storeId/:id',
            '/:language/product/:storeId/:id/:title',
            '/:language/:country/product/:storeId/:id/:title',
            '/product/:storeId/:id/:title',
          ]}>
            <Pages Components={Product} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/purchase',
            '/:language/purchase'
          ]}>
            <Pages Components={CheckoutPage} header={true} />
          </Route>
          
          <Route exact path={[
            '/purchase/guest',
            '/:language/purchase/guest',
            // '/purchase/guest/:tap',
          ]}>
            <Pages Components={CheckoutPage} header={true} />
          </Route>

          <Route exact path={[
            '/purchase/:success',
            '/:language/purchase/:success'
          ]}>
            <Pages Components={CheckoutConfirm} header={true} />
          </Route>
          <Route exact path={[
            '/shipping-policy',
            '/:language/shipping-policy'
          ]}>
            <Pages Components={ShippingPolicy} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/payment-policy',
            '/:language/payment-policy'
          ]}>
            <Pages Components={PaymentPolicy} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/returns-and-refund',
            '/:language/returns-and-refund'
          ]}>
            <Pages Components={ReturnsRefund} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/terms-and-conditions',
            '/:language/terms-and-conditions'
          ]}>
            <Pages Components={TermsConditions} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/privacy-policy',
            '/:language/privacy-policy'
          ]}>
            <Pages Components={PrivacyPolicy} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/about-us',
            '/:language/about-us'
          ]}>
            <Pages Components={AboutUs} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/faqs',
            '/:language/faqs'
          ]}>
            <Pages Components={FAQS} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/contact-us',
            '/:language/contact-us'
          ]}>
            <Pages Components={ContactUs} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/careers',
            '/:language/careers'
          ]}>
            <Pages Components={Careers} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/account/settings',
            '/:language/account/settings'
          ]}>
            <Pages Components={Settings} account={true} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/account/orders',
            '/:language/account/orders'
          ]}>
            <Pages Components={Membership} account={true} footer={true} header={true} />
          </Route>

          <Route exact path={[
            '/account',
            '/:language/account'
          ]}>
            <Pages Components={AccountOverview} account={true} footer={true} header={true} />
          </Route>
          <Route exact path={[
            '/account/overview',
            '/:language/account/overview'
          ]}>
            <Pages Components={AccountOverview} account={true} footer={true} header={true} />
          </Route>

          {/* <Route exact path={[
            '/account/payment-methods',
            '/:language/account/payment-methods'
          ]}>
            <Pages Components={PaymentMethod} account={true} footer={true} header={true} />
          </Route> */}
          <Route exact path={[
            '/account/support',
            '/:language/account/support'
          ]}>
            <Pages Components={Support} account={true} footer={true} header={true} />
          </Route>

          <Route exact path="/login">
            <Pages Components={Login} footer={true} header={true} />
          </Route>
          <Route exact path={["/", '/:language']}>
            <Pages Components={Home} footer={true} header={true} />
          </Route>
          <Route>
            <Pages Components={NotFound} footer={true} header={true} />
          </Route>

        </Switch>

      </Router>


    </div>
  );
}

export default App;
