import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
function OrdersList({ orders }) {
  const { userId, userToken, userEmail, countryCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  if (!userEmail && !userToken) {
    history.push('/')
  }

  const groupData = orders?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  function geBorderColor(states, state) {
    if (state === 'cancelled') {
      return '1px solid red'
    } else {
      if (states === 'delivered') {
        return '1px solid green'
      } else if (states === 'dispatched') {
        return '1px solid orange'
      } else if (states === 'shipping') {
        return '1px solid orange'
      } else {
        return '1px solid #eee'
      }
    }
  }

  return (
    <>
      <div className='op-item-card-section'>
        {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
          <div
            className='op-item-card-section-item'
            key={group}
            style={{
              background: '#f0f4f8',
              backgroundColor: '#fff0'
            }}
          >
            <div className='op-item-section'>
              {groupItems?.map((items, index) =>
                <Link
                  to={`/account/order/${orders?.order_number}`}
                  key={index}
                  style={{ background: '#fff', borderRadius: '10px', margin: '0px 0px' }}>
                  <div className='op-item-card' style={{ borderRadius: '100px', marginBottom: 0, border: geBorderColor(items.shipping_state, items.state) }}>
                    <img className='op-item-img' src={items.image} style={{ maxWidth: '100px', minWidth: '100px', minHeight: '100px', maxHeight: '100px', borderRadius: '100px', }} />
                  </div>
                </Link>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default OrdersList;