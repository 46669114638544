const thwiftyCat_a = [
  {
    link: 'stationary',
    name: 'stationary',
    image: 'https://cdn.ebtida.com/1704374368517-Stationary.png'
  },
  {
    link: 'sports',
    name: 'sports',
    image: 'https://cdn.ebtida.com/1704374368517-Sports-1.png'
  },
  {
    link: 'skincare',
    name: 'skincare',
    image: 'https://cdn.ebtida.com/1704374368517-Skincare-1.png'
  },
  {
    link: 'phone accessories',
    name: 'phone_accessories',
    image: 'https://cdn.ebtida.com/1704374368517-Phone-Accessories-1.png'
  },
  {
    link: 'shoes',
    name: 'shoes',
    image: 'https://cdn.ebtida.com/1704374368517-Shoes-1.png',
  },
  {
    link: 'headphones',
    name: 'headphones',
    image: 'https://cdn.ebtida.com/1704374368517-Headphones-1.png'
  },
  {
    link: 'gaming accessories',
    name: 'gaming_accessories',
    image: 'https://cdn.ebtida.com/1704374368517-Gaming-Accessories-1.png'
  },
  {
    link: 'board games',
    name: 'Board Games',
    image: 'https://cdn.ebtida.com/1706732527625-Board-Games-x.png'
  }
]



const thwiftyCM = [
  {
    link: '/store/deals',
    image: 'https://cdn.ebtida.com/1742931353200-thwifty-banner-mob.jpg'
  },
  {
    link: '/search/coleman',
    image: 'https://cdn.ebtida.com/1708632868222-Coleman-1.jpg'
  },
  {
    link: '/search/dr squatch',
    image: 'https://cdn.ebtida.com/1708632868222-Dr-Squatch-2.jpg'
  },
]



const thwiftyCat_b = [
  {
    link: 'luggage',
    name: 'luggage',
    image: 'https://cdn.ebtida.com/1704374368517-Luggage-1.png'
  },
  {
    link: 'mens grooming',
    name: 'mens_grooming',
    image: 'https://cdn.ebtida.com/1704374368517-Mens-Grooming-1.png'
  },
  {
    link: 'haircare',
    name: 'haircare',
    image: 'https://cdn.ebtida.com/1704374368517-haircare-1.png'
  },
  {
    link: 'gaming laptops',
    name: 'gaming_laptops',
    image: 'https://cdn.ebtida.com/1704374368517-Gaming-Laptops.png'
  },
  {
    link: 'fashion',
    name: 'fashion',
    image: 'https://cdn.ebtida.com/1704374368517-Fashion-1.png',
  },
  {
    link: 'home appliances',
    name: 'home_appliances',
    image: 'https://cdn.ebtida.com/1704374368517-Home-appliacnes.png'
  }
]

const thwiftyCat_c = [
  {
    link: 'healthcare',
    name: 'healthcare',
    image: 'https://cdn.ebtida.com/1704374368517-Healthcare-1.png'
  },
  {
    link: 'watches',
    name: 'watches',
    image: 'https://cdn.ebtida.com/1704374368517-watches-1.png'
  },
  {
    link: 'speakers',
    name: 'speakers',
    image: 'https://cdn.ebtida.com/1704482914220-Speakers-2.png'
  },
  {
    link: 'music instruments',
    name: 'music_instruments',
    image: 'https://cdn.ebtida.com/1704374368517-Music-Instruments.png'
  },
  {
    link: 'makeup',
    name: 'makeup',
    image: 'https://cdn.ebtida.com/1704374368517-Makeup-1.png',
  },
  {
    link: 'gaming console',
    name: 'gaming_console',
    image: 'https://cdn.ebtida.com/1704374368517-Gaming-Consoles-01.png'
  }
]


const thwiftyFashion = [
  {
    link: 'jacket',
    name: 'jackets',
    image: 'https://cdn.ebtida.com/1704482792549-Jackets.jpg'
  },
  {
    link: 'boot',
    name: 'boots',
    image: 'https://cdn.ebtida.com/1704482792549-Boots-1.jpg'
  },
  {
    link: 'hoodies and sweatshirts',
    name: 'hoodies_and_sweat_shirts',
    image: 'https://cdn.ebtida.com/1704482792549-Hoodies.jpg'
  },
  {
    link: 'jeans',
    name: 'jeans',
    image: 'https://cdn.ebtida.com/1704482792549-Jeans.jpg'
  },
  {
    link: 't-shirt',
    name: 'tshirts',
    image: 'https://cdn.ebtida.com/1704482792549-T-shirts.jpg',
  },
  {
    link: 'women dress',
    name: 'women_dress',
    image: 'https://cdn.ebtida.com/1704482792549-Ladies-Dress.jpg'
  }
]


const category_a = thwiftyCat_a
const category_b = thwiftyCat_b
const category_c = thwiftyCat_c
const fashion = thwiftyFashion
const carousel = thwiftyCM

const HomeData = {
  category_a: category_a,
  category_b: category_b,
  category_c: category_c,
  fashion: fashion,
  carousel: carousel
}


export default HomeData;